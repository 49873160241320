
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* In your global CSS file */
.sticky {
  position: sticky;
  top: 0;
}

/* Optional: Add smooth scrolling to the whole page */
html {
  scroll-behavior: smooth;
}
:root {
  --banner-height: 40px;
  --navbar-height: 80px;
}

.banner-offset {
  margin-top: calc(var(--banner-height) + var(--navbar-height));
}
